import {
  CSS3,
  Express,
  HTML5,
  Javascript,
  Mongodb,
  Nodejs,
  Reactjs,
  sharma,

  pharmacy,
  image,
  whatsaap,
} from "./assets";

export const projectStats = [
  {
    name: "Total Projects",
    value: "5+",
  },
  {
    name: "Website Design",
    value: "3+",
  },
  {
    name: "College projects",
    value: "3+",
  },
  {
    name: "Freelancing projects",
    value: "2",
  },
];

export const tools = [
  {
    name: "MongoDB",
    icon: Mongodb,
  },
  {
    name: "Express",
    icon: Express,
  },
  {
    name: "React",
    icon: Reactjs,
  },
  {
    name: "NodeJs",
    icon: Nodejs,
  },

];

export const skills = [
  {
    name: "ReactJs",
    value: 98,
    icon: Reactjs,
  },
  {
    name: "NodeJs",
    value: 60,
    icon: Nodejs,
  },
 
  {
    name: "MongoDB",
    value: 65,
    icon: Mongodb,
  },
  {
    name: "CSS3",
    value: 98,
    icon: CSS3,
  },
  {
    name: "HTML5",
    value: 98,
    icon: HTML5,
  },
  {
    name: "JavaScript",
    value: 88,
    icon: Javascript,
  },
  
];

export const projects = [
  {
    id: 1,
    title: "Sharma Assest Management ",
    cat: "Freelancing Project",
    url: "https://sharmaassetmanagement.com/",
    img: sharma,
  }, 
  {
    id: 2,
    title: "Pharmacy Management Projects ",
    cat: "Freelancing Project",
    url: "https://pharmacy-app-eight.vercel.app/",
    img: pharmacy,
  },
  {
    id: 3,
    title: "Ai-Image Generator",
    cat: "College Project",
    url: "https://ai-image-generator.honeysharma.site	",
    img: image,
  },
  {
    id: 4,
    title: "Whatsapp Web Clone",
    cat: "College Project",
    url: "https://whatsaapweb.honeysharma.site",
    img: whatsaap,
  },
];

export const softskills = [
  "Time management",
  "Communication",
  "Empathy",
  "Teamwork",
  "Helpfulness",
  "Creativity",
  "Self-learning",
  "Accountability",
  "Problem solving",
  "Open-mindedness",
  "Leadership",
  "Hard working",
  "Growing mindset",
  "Result Oriented",
];
