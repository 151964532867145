import React from "react";
import { AiFillTwitterCircle } from "react-icons/ai";
import { BsFacebook, BsInstagram, BsLinkedin } from "react-icons/bs";

const Footer = () => {
  return (
    <footer>
      <hr className='hidden dark:block' />
<div className="flex">
      
      
      <div className='w-full flex flex-col md:flex-row py-20 px-8 md:px-10 gap-10 lg:gap-20 justify-between '>
        
<div className="">
          <div className='flex flex-col md:flex-row text-md gap-5 md:gap-20 text-white '>
          <a href='#home'>Home</a>
          <a href='#about'>About</a>
          <a href='#projects'>Projects</a>
          <a href='#contact'>Contact</a>
          </div>

          <div className='flex mt-10 flex-row text-md gap-5 md:gap-20 text-white '>
          <p className='text-gray-400 text-md tracking-wider'>@2023 Honey Sharma</p>
          </div>
</div>


    <div className='flex flex-col gap-10 items-center justify-center pb-20 '>
        <p className='text-white font-semibold tracking-wider'>Social Media</p>

                <div className='flex gap-10 text-white text-2xl mb-10 '>

                  <a target="blanck" href="https://www.facebook.com/profile.php?id=100009829514920&mibextid=ZbWKwL"> <BsFacebook className='hover:scale-125' /></a>
                  <a target="blanck" href="https://www.instagram.com/honey.sharma5?igsh=MXEzYzh1bmg3a2xj"> <BsInstagram className='hover:scale-125' /></a>
                  <a target="blanck" href="https://www.linkedin.com/in/honey-sharma-a23016233"> <BsLinkedin className='hover:scale-110' /></a>
                  <a target="blanck"> <AiFillTwitterCircle className='hover:scale-125' /> </a>
                </div>

       
      </div>
        
      
      
        <div className='flex gap-5 text-md text-white '>
        <div className="flex h-10 w-10 items-center justify-center rounded bg-blue-900 text-gray-50">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="h-6 w-6">
                      <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2"></path>
                      <path d="M15 7a2 2 0 0 1 2 2"></path>
                      <path d="M15 3a6 6 0 0 1 6 6"></path>
                    </svg>
                  </div>
                  <div className="">
          <p>honey830547@gmail.com</p>
          <p>+91 8384043670</p>
          </div> 
        </div>
      </div>

      
      </div>
    </footer>
  );
};

export default Footer;
