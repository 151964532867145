import React from "react";
import { projects } from "../data";


const Works = () => {
  return (
    <div className='w-full flex flex-col py-20 px-8 md:px-10 gap-10 lg:gap-20 lg:py-20'>
      <h4 className='text-3xl text-center font-bold text-black dark:text-white mt-10 '>
        Projetcs
      </h4>

      <div className='flex flex-wrap gap-10 lg:gap-20 justify-center '>
        {projects.map((p, index) => (
          <div
            data-aos='fade-up'
            data-aos-offset='200'
            data-aos-delay='50'
            data-aos-duration='1000'
            data-aos-easing='ease-in-out'
            key={index}
            className='w-[350px] h-[350px] cursor-pointer shadow-xl hover:scale-110 ease-in-out duration-300 rounded-md '
          >
            <img
              src={p.img}
              alt={p.name}
              className='w-full h-[250px] object-cover rounded-md '
            />

            <div className='w-full h-[120px] bg-white dark:bg-[#04133e]'>
              <h4 className='text-2xl text-black dark:text-white font-semibold py-2 px-1 '>
                {p.title}
              </h4>
        <p className='text-sm text-orange-600 px-1'>{p.cat} <span> <a href={p.url} target="blanck" className='text-lg text-white fonnt-semibold mx-5 rounded-md py-1 px-5 bg-black dark:bg-[#F24F10]'>View</a> </span>  </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Works;
