// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore"; 
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB2l3Y9n2UvCZKgHd4QLiwI5OWljzr2pOg",
  authDomain: "portfolio-33218.firebaseapp.com",
  projectId: "portfolio-33218",
  storageBucket: "portfolio-33218.appspot.com",
  messagingSenderId: "884600069053",
  appId: "1:884600069053:web:c2c1dc337f73dbcf45d0d4",
  measurementId: "G-PXGLGC1QPV"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app); // Initialize the Realtime Database

export { app, db};